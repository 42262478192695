$("#user-new-form").validate({
  lang: 'ru',
  rules: {
    'user[fullname]': {
      required: true
    },
    'user[email]': {
      required: true,
      email: true
    },
    'user[phone_number]': {
      required: false
    },
    'user[role]': {
      required: true
    },
    'user[password]': {
      required: true,
      minlength: 8
    },
    'user[password_confirmation]': {
      required: true,
      minlength: 8,
      equalTo: '#user_password'
    }
  },
  errorPlacement: function(label, element) {
    label.addClass('mt-2 text-danger');
    label.insertAfter(element.parent());
  },
  highlight: function(element, errorClass) {
    $(element).parent().addClass('has-danger')
    $(element).addClass('form-control-danger')
  }
});
$("#user-edit-form").validate({
  lang: 'ru',
  rules: {
    'user[fullname]': {
      required: true
    },
    'user[email]': {
      required: true,
      email: true
    },
    'user[phone_number]': {
      required: false
    },
    'user[role]': {
      required: true
    },
    'user[password]': {
      required: false,
      minlength: 8
    },
    'user[password_confirmation]': {
      required: false,
      minlength: 8,
      equalTo: '#user_password'
    }
  },
  errorPlacement: function(label, element) {
    label.addClass('mt-2 text-danger');
    label.insertAfter(element.parent());
  },
  highlight: function(element, errorClass) {
    $(element).parent().addClass('has-danger')
    $(element).addClass('form-control-danger')
  }
});

if($('#user-edit-form, #user-new-form').length) {
  window.onbeforeunload = s => ""

  $('.navbar a, #sidebar a, footer a').on('click', function () {
    return confirm('Несохранённые данные пользователя будут утеряны, вы уверены что хотите покинуть страницу?')
  })

  $('#user-edit-form, #user-new-form').submit(function() {
    window.onbeforeunload = null
  })
}
