document.addEventListener('turbolinks:load', () => {
  require("./toast.js")
  require("./popover.js")
  require("./uis_form_validator.js")
  require("./cis_form_validator.js")
  require("./user_form_validator.js")
  require("./profile_form_validator.js")
  require("./statistics_page.js")
  delete require.cache[require.resolve("./toast.js")]
  delete require.cache[require.resolve("./popover.js")]
  delete require.cache[require.resolve("./uis_form_validator.js")]
  delete require.cache[require.resolve("./cis_form_validator.js")]
  delete require.cache[require.resolve("./user_form_validator.js")]
  delete require.cache[require.resolve("./profile_form_validator.js")]
  delete require.cache[require.resolve("./statistics_page.js")]

  if ($('.dropify').length) {
    const dropifyComponent = $('.dropify').dropify({
      messages: {
        'default': 'Выберите файл или перетащите его сюда',
        'replace': 'Чтобы заменить, выберите файл или перетащите его сюда',
        'remove':  'Удалить',
        'error':   'Что-то пошло не так...'
      }
    });
  }

  if ($(".js-example-basic-multiple").length) {
    $(".js-example-basic-multiple").select2({
      language: "ru"
    });
  }

  if ($(":input").length) {
    $(":input").inputmask();
  }

  $(".select2").select2({
    language: "ru"
  });

  $(".token-select2").select2({
    language: "ru",
    tags: true,
    tokenSeparators: [' '],
    dropdownCssClass: 'hidden',
    selectionCssClass: 'token-select2-selection'
  })

  $(".token-select2-selection").on("paste keydown", function(e) {
    const pastedData = e.originalEvent.clipboardData.getData('text')
                                                    .split(' ')
                                                    .filter(s => s.trim().length > 0)
                                                    .map(opt => opt.replace(/(\r\n|\n|\r)/gm, ""))
    pastedData.forEach(opt => {
      if ($('.token-select2').find("option[value='" + opt + "']").length === 0) {
        var newOption = new Option(opt, opt, true, true)
        $('.token-select2').append(newOption)
      }
    })
    const selected = $('.token-select2').select2('data').map(opt => opt.id)
    $('.token-select2').val(pastedData.concat(selected)).trigger('change')
    e.preventDefault()
  })

  $('.repeater').repeater({
    isFirstItemUndeletable: true
  });

  $('.datepicker').datepicker({
    language: 'ru'
  });
  if($('.input-daterange').length) {
    $('.input-daterange').datepicker({
      language: 'ru'
    });
  }

  $(document).on('click', ':not(form)[data-confirm]', function(e) {
    $(this).blur()
  })
})
