document.addEventListener('turbolinks:load', () => {
  $('.cis-ebs-checkbox').change(function(e) {
    showLoader()
    $.get(`/switchers/${e.target.value}/switch`, { "type": "ebs" })
      .done(function() {
        location.reload()
      })
      .always(function() {
        hideLoader()
      })
  })
  $('.cis-cpg-checkbox').change(function(e) {
    showLoader()
    $.get(`/switchers/${e.target.value}/switch`, { "type": "cpg" }).done(function() {
      location.reload()
    })
    .always(function() {
      hideLoader()
    })
  })

  function showLoader() {
    $('#loader-box').css('display', 'block')
  }
  function hideLoader() {
    $('#loader-box').css('display', 'none')
  }
})
