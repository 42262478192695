document.addEventListener('turbolinks:load', () => {
  $('#users-delete-button').click(function() {
    let answer = confirm('Удалить выбранных пользователей?')

    if (answer) {
      $.get('/users/bulk_destroy', { "user_ids[]": getUserIds() }).done(function() {
        location.reload()
      })
    }
  })

  $('.user-delete-checkbox').change(function() {
    let ids = getUserIds()
    if (ids.length > 0) {
      $('#users-delete-button').removeClass('disabled')
    } else {
      $('#users-delete-button').addClass('disabled')
    }
  })

  function getUserIds() {
    const user_ids = []
    $('.user-delete-checkbox:checked').each(function() {
      user_ids.push(this.value)
    })
    return user_ids
  }
})
