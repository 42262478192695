document.addEventListener('turbolinks:load', () => {

    if ($('#smtpSettingsTypeSelector').length) {
        let objSelector = $('input[type=radio][name=settings\\[smtp_settings_type\\]]');
        manageInputs(objSelector[0].checked ? objSelector[0].value : objSelector[1].value);

        $(objSelector).change(function () {
            manageInputs(this.value);
        });
    }

    function manageInputs(smtpTypeValue) {
        $('#custom_smtp_settings').css('display', smtpTypeValue == 'custom' ? 'block' : 'none');
        $('#default_smtp_settings').css('display', smtpTypeValue == 'default' ? 'block' : 'none');
        $('#default_allow_smtp_tls').css('cursor', smtpTypeValue == 'default' ? 'default' : 'pointer');
        $('#smtpSendTestMessage').css('pointer-events', smtpTypeValue != $('#smtpSavedSettingsType').val() ? 'none' : 'auto');
        $('#smtpSendTestMessage').css('display', smtpTypeValue != $('#smtpSavedSettingsType').val() ? 'none' : 'inline-block');
    }
})
