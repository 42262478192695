document.addEventListener('turbolinks:load', () => {
  $('#cis-delete-button').click(function() {
    let answer = confirm('Удалить выбранные КИС?')

    if (answer) {
      $.get('/ci_systems/bulk_destroy', { "cis_ids[]": getCisIds() }).done(function() {
        location.reload()
      })
    }
  })

  $('.cis-delete-checkbox').change(function() {
    let ids = getCisIds()
    if (ids.length > 0) {
      $('#cis-delete-button').removeClass('disabled')
    } else {
      $('#cis-delete-button').addClass('disabled')
    }
  })

  function getCisIds() {
    const cis_ids = []
    $('.cis-delete-checkbox:checked').each(function() {
      cis_ids.push(this.value)
    })
    return cis_ids
  }
})
