$(".cis-form").validate({
  lang: 'ru',
  rules: {
    'ci_system[name]': {
      required: true,
    },
    'ci_system[uni_system_id]': {
      required: true,
    },
    'ci_system[redirect_uri][]': {
      required: true,
    },
    'ci_system[user_ids][]': {
      required: true,
    }
    // SENTINEL-205
    // 'ci_system[certificate][cert_file]': {
    //   required: () => $('.hidden-successfull-cert').length === 0,
    //   accept: 'application/pkix-cert, application/x-x509-user-cert, application/x-x509-ca-cert'
    // }
  },
  errorPlacement: function(label, element) {
    label.addClass('mt-2 text-danger');
    label.insertAfter(element.parent());
  },
  highlight: function(element, errorClass) {
    $(element).parent().addClass('has-danger')
    $(element).addClass('form-control-danger')
  }
});
$('#ci_system_certificate_cert_file').rules("add", {
  messages: {
    accept: 'Выберите файл с корректным расширением (.crt, .cer)'
  }
});

if($('#cis-edit-form, #cis-new-form').length) {
  window.onbeforeunload = s => ""

  $('.navbar a, #sidebar a, footer a').on('click', function () {
    return confirm('Несохранённые данные КИС будут утеряны, вы уверены что хотите покинуть страницу?')
  })

  $('#cis-edit-form, #cis-new-form').submit(function() {
    window.onbeforeunload = null
  })
}
