const alert = $(".alert").text();
const notice = $(".notice").text();

if(notice) {

  $.toast({
    heading: 'Информация',
    text: notice,
    showHideTransition: 'slide',
    icon: 'info',
    loaderBg: '#46c35f',
    position: 'top-right'
  })
}

if(alert) {

  $.toast({
    heading: 'Ошибка',
    text: alert,
    showHideTransition: 'slide',
    icon: 'error',
    position: 'top-right'
  })
}
