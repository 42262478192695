const moment = require("moment")

document.addEventListener('turbolinks:load', () => {
  $('.cryptocontainers-daterange').on('changeDate', (e) => {
    let date = moment(e.date).format('DD.MM.YYYY')
    $.get(`/cryptocontainers/${e.target.attributes.certid.value}/expired_at`, { "expired_at": date }).done(function() {
      location.reload()
    })
  })
})
