const moment = require("moment")

$(".uni-form").validate({
  lang: 'ru',
  rules: {
    'uni_system[name]': {
      required: true,
      minlength: 3
    },
    'uni_system[mnemonic]': {
      required: true,
      minlength: 3
    },
    'uni_system[user_ids][]': {
      required: true,
    },
    'uni_system[signer_login]': {
      required: true
    },
    'uni_system[signer_pwd]': {
      required: true,
      minlength: 6
    },
    'uni_system[container_alias]': {
      required: true,
      minlength: 4
    },
    'uni_system[container_uploading_type]': {
      required: true
    },
    'uni_system[container_expired_at]': {
      required: true,
      is_date: true
    },
    'uni_system[signer_url]': {
      required: true,
      is_url: true,
      is_https_url: true
    },
    'uni_system[certificates][cert_files][]': {
      required: true,
      validate_files_count: true,
      validate_files_composition: true,
      validate_files_content: true
    },
    'uni_system[signer_esia_cert_test]': {
      required: true,
      minlength: 4
    },
    'uni_system[signer_esia_cert_prod]': {
      required: true,
      minlength: 4
    }
  },
  messages: {
    'uni_system[certificates][cert_files][]': {
      required: 'Необходимо загрузить файлы криптоконтейнера, полученного в УЦ',
      validate_files_count: "В загружаемом криптоконтейнере должно быть 6 файлов",
      validate_files_composition: "Неверный состав файлов криптоконтейнера",
      validate_files_content: "В загружаемом криптоконтейнере не должно быть пустых файлов"
    },
    'uni_system[container_expired_at]': {
      is_date: 'Дата некорректна'
    },
    'uni_system[signer_url]': {
      is_url: 'Ссылка некорректна.',
      is_https_url: 'Убедитесь, что используется https.'
    }
  },
  errorPlacement: function(label, element) {
    label.addClass('mt-2 text-danger');
    label.insertAfter(element);
  },
  highlight: function(element, errorClass) {
    $(element).parent().addClass('has-danger')
    $(element).addClass('form-control-danger')
  }
});

$.validator.addMethod (
  'validate_files_count',
  function(value, element) {
    return document.getElementById("uni_system_certificates_cert_files").files.length == 6;
  }
);

$.validator.addMethod (
  'validate_files_composition',
  function(value, element) {
    let wrong_files = new Array();
    const files = document.getElementById("uni_system_certificates_cert_files").files;
    const right_files = new Array('header.key', 'name.key', 'primary.key', 'masks.key', 'primary2.key', 'masks2.key');

    for (let i = 0; i < files.length; i++) {

      if(!right_files.includes(files[i].name))
        wrong_files.push(files[i].name);
    }

    return wrong_files.length == 0;
  }
);

$.validator.addMethod (
  'validate_files_content',
  function(value, element) {
    let wrong_files = new Array();
    const files = document.getElementById("uni_system_certificates_cert_files").files;

    for (let i = 0; i < files.length; i++) {
      if(files[i].size == 0)
        wrong_files.push(files[i].name);
    }
    return wrong_files.length == 0;
  }
);

$.validator.addMethod (
  'is_date',
  function(value, element) {
    return moment(value, 'DD.MM.YYYY', true).isValid()
  }
);

$.validator.addMethod (
  'is_url',
  function(value, element) {
    if ($(element).attr('validate-url') !== 'true') { return true }
    let url
    try {
      url = new URL(value)
    } catch (_) {
      return false
    }
    return url.protocol === "https:" || url.protocol === "http:"
  }
);

$.validator.addMethod (
  'is_https_url',
  function(value, element) {
    if ($(element).attr('validate-url') !== 'true') { return true }
    let url
    try {
      url = new URL(value)
    } catch (_) {
      return false
    }
    return url.protocol === "https:"
  }
);

if($('#uis-edit-form, #uis-new-form').length) {
  window.onbeforeunload = s => ""

  $('.navbar a, #sidebar a, footer a').on('click', function () {
    return confirm('Несохранённые данные ЕИС будут утеряны, вы уверены что хотите покинуть страницу?')
  })

  $('#uis-edit-form, #uis-new-form').submit(function() {
    window.onbeforeunload = null
  })
}
