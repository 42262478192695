document.addEventListener('turbolinks:load', () => {
  $('.copy-link-button').click(function(e) {
    const text = e.currentTarget.parentNode.firstElementChild.textContent
    navigator.clipboard.writeText(text);
    $.toast({
      heading: 'Информация',
      text: 'Ссылка скопирована!',
      showHideTransition: 'slide',
      icon: 'info',
      loaderBg: '#46c35f',
      position: 'top-right'
    })
  })
})
