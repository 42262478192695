// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// star-admin2 START
require("jquery")
require("bootstrap")
require("perfect-scrollbar")
require("chart.js")
require("bootstrap-datepicker")
require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.ru.min.js")
require("progressbar.js")
require("jquery-toast-plugin")
require("dropify")
require("select2")
require("select2/dist/js/i18n/ru")
require("inputmask")
require("inputmask/dist/inputmask/inputmask");
require("inputmask/dist/inputmask/inputmask.numeric.extensions");
require("inputmask/dist/inputmask/inputmask.date.extensions");
require("inputmask/dist/inputmask/jquery.inputmask");
require("jquery-validation")
require("jquery-validation/dist/additional-methods")
require("jquery-validation/dist/localization/messages_ru")

// некоторые скрипты из вендора необходимо оборачивать в эвент turbolinks:load для корректной работы с турболинками
require("../vendor/jquery.repeater")
require("../vendor/off-canvas.js")
require("../vendor/hoverable-collapse.js")
require("../vendor/template.js")
require("../vendor/settings.js")
require("../vendor/todolist.js")
require("../vendor/jq.tablesort.js")

// star-admin2 END

// application START
require("./application/main.js")
// application END

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import the application.scss we created for the CSS
import "../scss/application.scss";
// copy all static images under ../images to the output folder,
// and you can reference them with <%= image_pack_tag 'media/images/abc.png' %>
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
