$("#user-profile-form").validate({
  lang: 'ru',
  rules: {
    'user[email]': {
      required: true,
      email: true
    },
    'user[fullname]': {
      required: true
    },
    'user[password]': {
      required: false,
      minlength: 8
    },
    'user[password_confirmation]': {
      required: false,
      minlength: 8,
      equalTo: '#user_password'
    },
    'user[notification_setting][monthday]': {
      required: '#profile-monthly-checkbox:checked',
      number: true,
      min: 1,
      max: 31
    }
  },
  errorPlacement: function(label, element) {
    label.addClass('mt-2 text-danger');
    label.insertAfter(element);
  },
  highlight: function(element, errorClass) {
    $(element).parent().addClass('has-danger')
    $(element).addClass('form-control-danger')
  }
});

if($('#user-profile-form').length) {
  window.onbeforeunload = s => ""

  $('.navbar a, #sidebar a, footer a').on('click', function () {
    return confirm('Несохранённые данные профиля будут утеряны, вы уверены что хотите покинуть страницу?')
  })

  $('#user-profile-form').submit(function() {
    window.onbeforeunload = null
  })
}
